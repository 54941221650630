.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.color{
  color: #fff !important;
  padding: 50px;
  text-align: justify;
}

.MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused, .MuiInputLabel-root.Mui-focused, .MuiIconButton-root, .MuiChip-root, .MuiSvgIcon-root {
  color: #ff66c4 !important;
  border-color: #ff66c4 !important;
}

.Mui-active{
  color: #ff66c4 !important;
}

.MuiToggleButton-root{
  color: #0E100F !important;
  background-color: whitesmoke !important;
}

.Mui-selected{
  background-color: #ff66c4 !important;
  color: #fff !important;
}

.Mui-completed{
  color: #f5f5f5 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  /*MyAppFitness*/
  background-color: #1C1F1E;

  /*GPFT*/
  /*  background-color: #e5e5e5;*/

}

.cell-start{
  /* color: red !important; */
  justify-content: center !important;
}
/* 
.MuiAccordion-rounded{
  max-width: 100%;
} */

.MuiDataGrid-cell:focus {
  outline: none !important;
}

.Mui-disabled {
  color: grey !important;
}


::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  /*MyAppFitness*/
  background: #0E100F;

  /*GPFT*/
  /*background: #e5e5e5;*/
}
::-webkit-scrollbar-thumb:hover {
  /*MyAppFitness*/
  background: #0E100F;

  /*GPFT*/
  /*background: #c5c9ca;*/
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #ff66c4;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
  /* display: none; */
}